import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TertiaryButton as Button } from './Buttons';

const Wrapper = styled.div`
  position: relative;
`;

const AddToCartButton = ({ productName, variants }) => {
  //

  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Button
        onClick={() =>
          dispatch({
            type: 'TOGGLE_PRODUCT_SELECTOR',
            variants,
            productName,
          })
        }
      >
        ADD TO CART
      </Button>
    </Wrapper>
  );
};

export default AddToCartButton;

AddToCartButton.propTypes = {
  variants: PropTypes.array.isRequired,
  productName: PropTypes.string.isRequired,
};
