import React, { useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Block, MarkdownStyles, SectionCopy, SectionItemHeader, ProductMdx } from '../../components';
import Product from './SingleProduct';

const Section = styled.section`
  /* @media (min-width: 1280px) {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 960px 1fr;
  } */
`;

const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0 2rem;
  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const SideBar = styled.div``;

const SingleProductLayout = ({ data }) => {
  //
  const { description, body } = data;

  return (
    <Section>
      <div>
        <Product data={data} />
        <Container>
          <ProductMdx>
            <MDXRenderer>{body}</MDXRenderer>
          </ProductMdx>
        </Container>
      </div>
      {/* <SideBar>Hello</SideBar> */}
    </Section>
  );
};

export default SingleProductLayout;

SingleProductLayout.propTypes = {
  data: PropTypes.object.isRequired,
};
