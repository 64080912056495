import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import AddToCartButton from '../../components/AddToCartButton';
import Facebook from '../../components/Svg/Facebook';
import { DicscountLabel, OptionInfo, FormLabel, Price, DiscountedPrice } from '../../components';
import { defaultTheme, breakpoints } from '../../styles';

const Wrapper = styled.div`
  display: grid;
  padding-top: 2rem;
  @media (min-width: 600px) {
    padding-bottom: 8rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
`;

const ImageGridCell = styled.div`
  @media (min-width: 960px) {
    display: flex;
    justify-content: flex-end;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  @media (min-width: 960px) {
    width: 464px;
  }
`;

const ImageSharp = styled(Img)`
  width: 100%;
  max-height: 400px;
  background: #edf2f7;
`;

const Tag = styled.span`
  margin-right: 0.5rem;
  padding: 6px 12px;
  font-family: var(--primary-font);
  background: ${defaultTheme.productImgBg};
  color: #000000;
  font-weight: var(--primary-font-weight-regular);
  font-size: var(--primary-font-size-sublevel-1);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 0.75rem;
  border: none;
`;

const Metadata = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  svg {
    height: 22px;
    margin-left: 0.5rem;
  }
`;

const CopyGridCell = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 5rem 2rem;
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
`;

const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  td {
    padding-top: 6px;
    padding-bottom: 2px;
  }
  td:nth-child(1),
  td:nth-child(2) {
    padding-right: 1rem;
  }
  margin-bottom: 2rem;
`;

const ProductName = styled.h1`
  color: ${defaultTheme.text};
  font-family: var(--primary-font);
  font-size: var(--primary-font-size-level-2);
  font-weight: var(--primary-font-weight-bold);
  line-height: 1.3;
  margin-bottom: 0.5rem;
  @media (min-width: ${breakpoints.large}px) {
    font-size: var(--primary-font-size-level-2);
  }
`;

const SingleProduct = ({ data }) => {
  //
  const { title, images, variants, frontmatter } = data;

  const image = images[0].localFile.childImageSharp.fluid;
  const { price, compareAtPrice } = variants[0];

  const savings = (retailPrice, originalPrice) => {
    return Math.ceil(((Number(originalPrice) - Number(retailPrice)) / Number(originalPrice)) * 100);
  };

  return (
    <Wrapper>
      <ImageGridCell>
        <ImageContainer>
          <ImageSharp fluid={image} imgStyle={{ objectFit: 'contain' }} />
          {compareAtPrice && Number(compareAtPrice) > 0 && (
            <DicscountLabel>SAVE {savings(price, compareAtPrice)}%</DicscountLabel>
          )}
        </ImageContainer>
      </ImageGridCell>
      <CopyGridCell>
        <div>
          <ProductName>{title}</ProductName>
          <Metadata>
            {/* <FormLabel style={{ marginLeft: '1rem' }}>Share</FormLabel>
            <Facebook /> */}
          </Metadata>
          <Table>
            <tbody>
              {variants.map(variant => {
                return (
                  <tr key={variant.id}>
                    <td>
                      <OptionInfo>{variant.title !== 'Default Title' && variant.title}</OptionInfo>
                    </td>
                    {variant.compareAtPrice && Number(compareAtPrice) > 0 && (
                      <td>
                        <DiscountedPrice>
                          <strike>MYR ${variant.compareAtPrice.slice(0, -3)}</strike>
                        </DiscountedPrice>
                      </td>
                    )}
                    <td>
                      <Price>
                        MYR <span>{variant.price.slice(0, -3)}</span>
                      </Price>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <AddToCartButton variants={variants} productName={title} />
        </div>
      </CopyGridCell>
    </Wrapper>
  );
};

export default SingleProduct;

SingleProduct.propTypes = {
  data: PropTypes.object.isRequired,
};
