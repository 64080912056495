import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { SEO } from '../components';
import Layout from '../page-components/products/SingleProductLayout';

const productTemplate = ({ data, location: { pathname } }) => {
  //

  const { queriedProduct, queriedMdx } = data;
  const merged = { ...queriedProduct, ...queriedMdx };

  return (
    <article>
      <SEO title={queriedProduct.title} description={queriedProduct.description} />
      <Layout data={merged} />
    </article>
  );
};

export default productTemplate;

productTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query($slug: String!) {
    queriedProduct: shopifyProduct(fields: { slug: { eq: $slug } }) {
      ...ShopifySingleProduct
    }
    queriedMdx: mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
